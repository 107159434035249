import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconChecklist: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 40 40">
    <path d="M12.5003 20.1668H9.16699V21.8335H12.5003V20.1668Z" />
    <path d="M26.667 24.1668H20.0003V22.5002H26.667V24.1668Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.319 5.8335C10.4401 5.8335 8.79358 7.09108 8.29919 8.90384L7.00057 13.6654L4.76923 12.1779L3.84473 13.5646L6.37694 15.2528L4.99828 17.5505C4.45433 18.4571 4.16699 19.4945 4.16699 20.5517V27.5002C4.16699 29.8013 6.03247 31.6668 8.33366 31.6668C10.6348 31.6668 12.5003 29.8013 12.5003 27.5002V25.8335H16.7081C17.1262 30.0446 20.6792 33.3335 25.0003 33.3335C27.0014 33.3335 28.8377 32.6282 30.2743 31.4526L36.0778 37.2561L37.2563 36.0776L31.4528 30.2741C32.6284 28.8375 33.3337 27.0012 33.3337 25.0002C33.3337 23.6963 33.0342 22.4624 32.5003 21.3634V20.5517C32.5003 19.4945 32.213 18.4571 31.669 17.5505L30.2904 15.2528L32.8226 13.5646L31.8981 12.1779L29.6177 13.6981L27.9833 8.70417C27.4229 6.9917 25.8252 5.8335 24.0233 5.8335H12.319ZM30.3568 18.6161C30.3199 18.5458 30.2809 18.4764 30.2399 18.408L28.6563 15.7687C28.5571 15.8104 28.4481 15.8335 28.3337 15.8335H8.33366C8.21926 15.8335 8.11025 15.8104 8.01101 15.7687L6.42744 18.408C6.0389 19.0556 5.83366 19.7966 5.83366 20.5517V24.1668H16.7081C16.7894 23.3484 16.989 22.5649 17.2897 21.8335H15.8337V20.1668H18.211C19.722 18.0483 22.1998 16.6668 25.0003 16.6668C27.0399 16.6668 28.9083 17.3995 30.3568 18.6161ZM28.0174 14.1668H8.59137L9.90713 9.34237C10.2038 8.25472 11.1917 7.50016 12.319 7.50016H24.0233C25.1044 7.50016 26.063 8.19508 26.3993 9.22257L28.0174 14.1668ZM5.83366 27.5002V25.8335H10.8337V27.5002C10.8337 28.8809 9.71437 30.0002 8.33366 30.0002C6.95295 30.0002 5.83366 28.8809 5.83366 27.5002ZM25.0003 31.6668C27.1056 31.6668 28.983 30.6909 30.2048 29.1668H19.7958C21.0176 30.6909 22.895 31.6668 25.0003 31.6668ZM18.8183 27.5002H31.1824C31.4949 26.7281 31.667 25.8842 31.667 25.0002C31.667 21.3183 28.6822 18.3335 25.0003 18.3335C21.3184 18.3335 18.3337 21.3183 18.3337 25.0002C18.3337 25.8842 18.5057 26.7281 18.8183 27.5002Z"
    />
  </Icon>
);
