import dynamic from 'next/dynamic';
import { FC } from 'react';
import useSWRImmutable from 'swr/immutable';

import { fetchVehicleFilters } from 'src/data/ProductApi/ProductApiListVehicles';
import { MICROCOPY } from 'src/data/microcopy/microcopyDictionary';
import { Button } from 'src/general/components/Button/Button';
import { Divider } from 'src/general/components/Divider/Divider';
import { getMicrocopyBulk } from 'src/stores/microcopyStore';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';
import { formatNumberComma } from 'src/utils/utils';

import styles from './FindACar.module.scss';

const SearchWidget = dynamic(() => import('src/general/components/HeaderMenu/SearchWidget/SearchWidget'), {
  ssr: false,
});
const KeywordSearch = dynamic(
  () => import('src/general/components/KeywordSearch/KeywordSearch').then((mod) => mod.KeywordSearch),
  {
    ssr: false,
  },
);

interface FindACarProps {
  total?: number;
  className?: string;
}

export const FindACar: FC<FindACarProps> = ({ total = 0, className = '' }) => {
  const { title, searchAllCars } = getMicrocopyBulk({
    title: [MICROCOPY.HYGIENE_HOMEPAGE_SEARCH_TITLE],
    searchAllCars: [
      MICROCOPY.HYGIENE_HOMEPAGE_SEARCH_ALL_CARS,
      false,
      {
        total: total > 0 ? `${formatNumberComma(total)}` : '',
      },
    ],
  });
  const { data: vehicleFilters } = useSWRImmutable('/vehicle-filters', fetchVehicleFilters);

  return (
    <aside className={`${styles.root} ${className}`}>
      <header className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
      </header>
      <div className={styles.search}>
        <KeywordSearch vehicleFilters={vehicleFilters} className={styles.searchForm} />
        <SearchWidget
          className={styles.searchTrigger}
          keywordSearch={<KeywordSearch isMobile vehicleFilters={vehicleFilters} />}
        />
      </div>
      <Divider>or</Divider>
      <Button
        data-testid={MICROCOPY.HYGIENE_HOMEPAGE_SEARCH_ALL_CARS}
        href="/used-cars"
        fullWidth
        className={styles.button}
        onClick={() => {
          pushToDataLayer({
            event: 'search_click_browse_all_cars',
            value: total,
          });
        }}
      >
        {searchAllCars}
      </Button>
    </aside>
  );
};
