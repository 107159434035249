import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const Icon7Days: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 40 40">
    <path d="M24.167 15.0002H15.8337V17.5002H17.5003V16.6668H21.7266C21.4566 16.9003 21.2082 17.164 20.9812 17.4434C20.4127 18.1431 19.9342 18.9948 19.5494 19.8745C18.7857 21.6201 18.3337 23.6095 18.3337 25.0002H20.0003C20.0003 23.8908 20.3817 22.1303 21.0763 20.5425C21.4206 19.7555 21.8275 19.0447 22.2747 18.4944C22.7269 17.9378 23.1777 17.5972 23.5972 17.4574L24.167 17.2675V15.0002Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7319 3.76779C20.5859 3.50007 20.3053 3.3335 20.0003 3.3335C19.6954 3.3335 19.4148 3.50007 19.2688 3.76779L14.5057 12.5002H5.00035C4.6633 12.5002 4.35943 12.7032 4.23045 13.0146C4.10147 13.326 4.17276 13.6844 4.41109 13.9228L12.3715 21.8832L8.38223 33.0532C8.26623 33.378 8.3623 33.7407 8.62386 33.9655C8.88542 34.1903 9.25844 34.2307 9.5621 34.0672L20.0003 28.4466L30.4386 34.0672C30.7423 34.2307 31.1153 34.1903 31.3768 33.9655C31.6384 33.7407 31.7345 33.378 31.6185 33.0532L27.6292 21.8832L35.5896 13.9228C35.8279 13.6844 35.8992 13.326 35.7703 13.0146C35.6413 12.7032 35.3374 12.5002 35.0004 12.5002H25.495L20.7319 3.76779ZM15.7319 13.7325L20.0003 5.9071L24.2688 13.7325C24.4148 14.0003 24.6954 14.1668 25.0004 14.1668H32.9885L26.0778 21.0776C25.85 21.3053 25.7739 21.6438 25.8822 21.9471L29.3196 31.5718L20.3954 26.7664C20.1488 26.6336 19.8519 26.6336 19.6053 26.7664L10.6811 31.5718L14.1185 21.9471C14.2268 21.6438 14.1507 21.3053 13.9229 21.0776L7.01219 14.1668H15.0004C15.3053 14.1668 15.5859 14.0003 15.7319 13.7325Z"
    />
  </Icon>
);
