import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconWarranty: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 40 40">
    <path d="M18.9224 24.7558L27.845 15.8332L26.6665 14.6547L18.3331 22.988L14.1665 18.8213L12.988 19.9998L17.7439 24.7558C18.0693 25.0812 18.597 25.0812 18.9224 24.7558Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3383 4.23833C20.1228 4.14256 19.8769 4.14256 19.6614 4.23833C18.893 4.57982 18.1452 4.9692 17.3776 5.36886C14.6554 6.78628 11.6844 8.33317 6.66634 8.33317C6.2061 8.33317 5.83301 8.70627 5.83301 9.1665C5.83301 14.3741 6.24757 20.1335 8.18537 24.93C10.1424 29.7742 13.6695 33.6623 19.8253 34.9813C19.9404 35.006 20.0594 35.006 20.1745 34.9813C26.3301 33.6623 29.8571 29.7741 31.814 24.93C33.7518 20.1335 34.1663 14.3741 34.1663 9.1665C34.1663 8.70627 33.7932 8.33317 33.333 8.33317C28.315 8.33317 25.3444 6.78636 22.6223 5.36897C21.8546 4.96927 21.1068 4.57986 20.3383 4.23833ZM9.73069 24.3057C8.014 20.0564 7.54771 14.9055 7.50331 9.98571C12.4835 9.816 15.6691 8.15062 18.2779 6.78681C18.8774 6.4734 19.4465 6.17588 19.9999 5.91614C20.5533 6.17591 21.1224 6.47344 21.722 6.7869C24.3306 8.15068 27.516 9.81601 32.496 9.98571C32.4516 14.9055 31.9854 20.0564 30.2687 24.3057C28.4947 28.697 25.4026 32.0979 19.9999 33.3133C14.5969 32.0979 11.5047 28.6969 9.73069 24.3057Z"
    />
  </Icon>
);
