import classNames from 'classnames';
import Image from 'next/image';
import { FC, useEffect, useMemo } from 'react';

import { ContentfulSectionPageSection } from 'src/data/Contentful/ContentfulTypes';
import { MICROCOPY } from 'src/data/microcopy/microcopyDictionary';
import { Icon7Days } from 'src/general/Icons/Icon7Days';
import { IconChecklist } from 'src/general/Icons/IconChecklist';
import { IconWarranty } from 'src/general/Icons/IconWarranty';
import { carmaGenericImageLoader } from 'src/general/helpers/carmaGenericImageLoader';
import { FindACar } from 'src/hygiene/components/FindACar/FindACar';
import { getMicrocopyBulk } from 'src/stores/microcopyStore';

import { SectionProps } from '../SectionManager/types';
import styles from './HeroBanner.module.scss';

export type HeroBannerProps = SectionProps<ContentfulSectionPageSection, 'total'>;

export const HeroBanner: FC<HeroBannerProps> = ({ sectionData: { total } }) => {
  const { heading, uspReturn7Days, uspWarranty, uspChecklist, carMatch } = getMicrocopyBulk({
    heading: [MICROCOPY.HYGIENE_HOMEPAGE_NEW_HERO_HEADING],
    uspReturn7Days: [MICROCOPY.HYGIENE_HOMEPAGE_NEW_USP_RETURN, true],
    uspWarranty: [MICROCOPY.HYGIENE_HOMEPAGE_USP_WARRANTY, true],
    uspChecklist: [MICROCOPY.HYGIENE_HOMEPAGE_NEW_USP_CHECKLIST, true],
    carMatch: [MICROCOPY.HYGIENE_HOMEPAGE_HERO_CARMATCH, true],
  });

  const usps = useMemo(
    () => [
      { key: '7days', Icon: Icon7Days, content: uspReturn7Days },
      { key: 'warranty', Icon: IconWarranty, content: uspWarranty },
      { key: 'checklist', Icon: IconChecklist, content: uspChecklist },
    ],
    [uspReturn7Days, uspWarranty, uspChecklist],
  );

  // Top secret shenanigans
  useEffect(() => {
    import('src/hygiene/sections/NewHeroBanner/crazyVaclav').then((module) => {
      module.putItInH(module.crazyVaclav);
      module.registerCrazyVaclav();
    });
  }, []);

  return (
    <section className={classNames(styles.root, 'HeroBanner')}>
      <div className={styles.backgroundMobile}>
        <Image
          src="https://img.carma.com.au/ui-assets/HeroBanner/background-mobile.jpg"
          loader={carmaGenericImageLoader}
          layout="responsive"
          alt=""
          width={2304}
          height={2304}
          priority
        />
      </div>
      <div className={styles.background}>
        <Image
          src="https://img.carma.com.au/ui-assets/HeroBanner/background.jpg"
          loader={carmaGenericImageLoader}
          layout="fill"
          alt=""
          priority
        />
      </div>
      <div className={styles.container}>
        <header>
          <h1 className={classNames(styles.heading, 'HeroBanner-heading')} data-testid="HERO_BANNER_TITLE">
            {heading}
          </h1>
        </header>
        <FindACar className={styles.search} total={total} />
        <div className={styles.carMatch}>{carMatch}</div>
      </div>
      <footer className={styles.footer}>
        {usps.map(({ key, Icon, content }) => (
          <div key={key} className={classNames(styles.usp, 'HeroBanner-usps')}>
            <Icon className={styles.icon} />
            {content}
          </div>
        ))}
      </footer>
    </section>
  );
};
